import './style.css';
import foto1 from '../../assets/FOTO_01.jpg';
import foto2 from '../../assets/FOTO_03.jpg';
import foto3 from '../../assets/FOTO_02.jpeg';


function Info2() {
    return (
        <div id="info2">
            <div class="container">
                <div class="card-group">
                    <div class="card m-2 mt-5">
                        <img src={foto1} class="card-img-top" alt=""/>
                        <div class="card-body">
                            <h2 class="card-title"><b>ARMAÇÃO DE<br/> ÓCULOS</b></h2>
                            <p class="card-text">
                                Com valores a partir de R$99,99<br />
                                para óculos grau ou óculos de sol<br />
                                nas linhas feminina, masculina e <br />
                                infantil
                            </p>
                            <a class="btn" target="_blank" rel='noreferrer' href='https://api.whatsapp.com/send?1=pt_BR&phone=551230033088&text=Ol%C3%A1!%20Eu%20gostaria%20de%20saber%20sobre%20as%20promo%C3%A7%C3%B5es%20Ziggs%20e%20agendar%20meu%20Teste%20de%20Vis%C3%A3o%20Gratuito'>Receber atendimento</a>
                        </div>
                    </div>
                    <div class="card m-2">
                        <img src={foto2} class="card-img-top" alt=""/>
                        <div class="card-body">
                            <h2 class="card-title"><b>LENTES E<br/> ACESSÓRIOS</b></h2>
                            <p class="card-text">
                                trabalhamos com todos os tipos<br />
                                de lentes de óculos. Além disso, temos<br />
                                preços e descontos exclusivos.
                            </p>
                            <a class="btn" target="_blank" rel='noreferrer' href='https://api.whatsapp.com/send?1=pt_BR&phone=551230033088&text=Ol%C3%A1!%20Eu%20gostaria%20de%20saber%20sobre%20as%20promo%C3%A7%C3%B5es%20Ziggs%20e%20agendar%20meu%20Teste%20de%20Vis%C3%A3o%20Gratuito'>Receber atendimento</a>
                        </div>
                    </div>
                    <div class="card m-2 mt-5">
                        <img src={foto3} class="card-img-top" alt=""/>
                        <div class="card-body">
                            <h2 class="card-title"><b>RAPIDEZ E<br/> GARANTIA</b></h2>
                            <p class="card-text">
                            Os seus óculos ficam prontos a partir<br />
                            de 1 hora e oferecemos garantia de 1<br />
                            ano para sua compra.
                            </p>
                            <a class="btn" target="_blank" rel='noreferrer' href='https://api.whatsapp.com/send?1=pt_BR&phone=551230033088&text=Ol%C3%A1!%20Eu%20gostaria%20de%20saber%20sobre%20as%20promo%C3%A7%C3%B5es%20Ziggs%20e%20agendar%20meu%20Teste%20de%20Vis%C3%A3o%20Gratuito'>Receber atendimento</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info2;