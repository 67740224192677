import './style.css';

import whats from '../../assets/WhatsApp.svg_.png';


function Footer() {
    return (
        <div id="footer">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <div>

                        </div>
                    </div>
                    <div class="col-sm">

                    </div>
                </div>
            </div>
            <div id="icon-whats">
                <a href="https://api.whatsapp.com/send?1=pt_BR&phone=551230033088&text=Ol%C3%A1!%20Eu%20gostaria%20de%20saber%20sobre%20as%20promo%C3%A7%C3%B5es%20Ziggs%20e%20agendar%20meu%20Teste%20de%20Vis%C3%A3o%20Gratuito" target="_blank" rel='noreferrer'>
                    <img class="icon" src={whats} alt=""/>
                </a>
            </div>
        </div>
    );
}

export default Footer;