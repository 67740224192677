import './style.css';

function Info1() {
    return (
        <div id="info1">
            <p id="titulo">CONFIANÇA E QUALIDADE QUE VOCÊ <br /> SÓ ENCONTRA NA GIGANTE DOS ÓCULOS</p>

            <div class="container text-center">
                <div class="row">
                    <div class="col">
                        <span class="texto-num">As Melhores</span><br />
                        <span class="texto">Marcas do mercado</span>
                    </div>
                    <div class="col order-5">
                        <span class="texto-num">+2mil<br /></span>
                        <span class="texto">Modelos disponíveis</span>
                    </div>
                    <div class="col order-1">
                        <span class="texto">Agende seu</span><br />
                        <span class="testeV">TESTE DE VISÃO<br />GRATUITO!</span>
                    </div>
                </div>
            </div>

            <div class="d-grid gap-2 col-4 mx-auto mt-5">
                <a class="btn" target="_blank" rel='noreferrer' href='https://api.whatsapp.com/send?1=pt_BR&phone=551230033088&text=Ol%C3%A1!%20Eu%20gostaria%20de%20saber%20sobre%20as%20promo%C3%A7%C3%B5es%20Ziggs%20e%20agendar%20meu%20Teste%20de%20Vis%C3%A3o%20Gratuito'>Receber atendimento</a>
            </div>


        </div>
    );
}

export default Info1;