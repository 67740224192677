import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import Header from './components/header/header'
import Form from './components/form/form'
import Info1 from './components/info1/info1'
import Info2 from './components/info2/info2'
import Info3 from './components/info3/info3'
import Footer from './components/footer/footer'

function App() {

  return (
    <div>
      <Header />
      <Form />
      <Info1 />
      <Info2 />
      <Info3 />
      <Footer />
    </div>
  );

}

export default App;
