import './style.css';

function Header() {
    return (
        <div id="header">
            
        </div>
    );
}

export default Header;