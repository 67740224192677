import './style.css';

import sp from '../../assets/SP.png';
import rj from '../../assets/rj.png';
import ba from '../../assets/ba.png';
import mg from '../../assets/mg.png';
import foguete from '../../assets/foguete.png';

function Info3() {
    return (
            <div id="info3">
            <div class='container-fluid'>
                <br></br>
                <div class='col-md-12 mx-auto pki'><img src={foguete} width='5%' class="" alt="..." />  MAIS DE 30 UNIDADES PARA VOCÊ</div>
                <br></br>
                <div class='col-md-8 mx-auto pkl'>
                <div class="accordion accordion-flush pkj" id="accordionFlushExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <img src={sp} class="xxl" alt="..." /> &nbsp;&nbsp; SÃO PAULO 
                    </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Barra Funda - Ziggs</span><br></br>
                            <span class='vps'>Avenida Francisco Matarazzo, 750 - Água Branca / SP</span><br></br>
                            <span class='vpu'>Estamos localizados na mesma calçada do Graal. Sentindo Rod dos Imigrantes.</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions/br/sp/av.-francisco-matarazzo,-750?place=Ek9Bdi4gRnJhbmNpc2NvIE1hdGFyYXp6bywgNzUwIC0gw4FndWEgQnJhbmNhLCBTw6NvIFBhdWxvIC0gU1AsIDA1MDAxLTkwMCwgQnJhemlsIjESLwoUChIJP5WHcgJYzpQRaNihltLWmY4Q7gUqFAoSCUdHoMP4V86UEdIQuMvYJz8Z" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://maps.app.goo.gl/joEvEVv88UgLrn81A" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vps'><a href="tel:+551125483032">(11) 3862-4723</a></span><br></br>
                            <span class='vpu'>De Segunda a Sábado das 09h às 20h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS DAS 09H ÀS 17H</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Aricanduva</span><br></br>
                            <span class='vps'>Avenida Aricanduva, 3640 - Vila California / SP</span><br></br>
                            <span class='vpu'>A 50m da Avenida Itaquera (sentido bairro), ao lado da loja Mamãe Presentes e do Atacadão</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.5534435%2C-46.5191185" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/Bj2cBDzMvfNyzdtf9" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551149345601">(11) 4934-5601</a>&nbsp;-&nbsp;<a href="mailto:aricanduva@oticasgassi.com.br">aricanduva@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Bandeirantes</span><br></br>
                            <span class='vps'>Avenida dos Bandeirantes, 4860 - Planalto Paulista - São Paulo / SP</span><br></br>
                            <span class='vpu'>Estamos localizados na mesma calçada do Graal. Sentindo Rod dos Imigrantes.</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://ul.waze.com/ul?place=ChIJrSloKGZazpQR8mgXja3YG_U&amp;ll=-23.62180550%2C-46.65409400&amp;navigate=yes&amp;utm_campaign=default&amp;utm_source=waze_website&amp;utm_medium=lm_share_location" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/mJdJkzMFqa8KAQyg7" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551125483032">(11) 2548-3032</a>&nbsp;-&nbsp;<a href="mailto:bandeirantes@oticasgassi.com.br">bandeirantes@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS DAS 09H ÀS 16H</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Butantã</span><br></br>
                            <span class='vps'>Avenida Professor Francisco Morato, 236 - Butantã / SP</span><br></br>
                            <span class='vpu'>A 200 metros do Terminal de Ônibus / Metrô Butantã</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.57364396%2C-46.70673251" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/6aZmchkPd99m5z1P8" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551130317073">(11) 3031-7073</a>&nbsp;-&nbsp;<a href="mailto:butanta@oticasgassi.com.br">butanta@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Campinas</span><br></br>
                            <span class='vps'>Rua José Paulino - 462 - Centro - Campinas / SP</span><br></br>
                            <span class='vpu'>Estamos localizados à uma quadra da Av. Aquidaban, próximo às lojas de noivas</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-22.9098788%2C-47.05860615" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/RMqehZJhpPwRm3196" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a><br />
                            <img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/estacionamento-conveniado.png" width="200" /></p>
                            <span class='vpu'><p><a href="tel:+551932311421">(19) 3231-1421</a>&nbsp;-&nbsp;<a href="mailto:campinas@oticasgassi.com.br">campinas@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 17h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Congonhas</span><br></br>
                            <span class='vps'>Avenida Washington Luís, 4859 - Santo Amaro / SP</span><br></br>
                            <span class='vpu'>23 de Maio sentido bairro. Próximo a ponte Roberto Marinho</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.6380061%2C-46.6720651" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/E693WfFa9vbnHzNk6" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551155423355">(11) 5542-3355</a>&nbsp;-&nbsp;<a href="mailto:congonhas@oticasgassi.com.br">congonhas@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Guarapiranga</span><br></br>
                            <span class='vps'>Avenida Guarapiranga, 795 - Socorro - São Paulo/SP</span><br></br>
                            <span class='vpu'>Estamos localizado em frente ao Shopping Fiesta.</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.6380061%2C-46.6720651" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/E693WfFa9vbnHzNk6" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551125399750">(11) 2539-9750</a>&nbsp;-&nbsp;<a href="mailto:guarapiranga@oticasgassi.com.br">guarapiranga@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Guarulhos</span><br></br>
                            <span class='vps'>Rua Padre Celestino, 148 – Guarulhos / SP</span><br></br>
                            <span class='vpu'>Travessa da Rua Dom Pedro ll.A 100 metros do Calçadão de Guarulhos</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.47066679%2C-46.52708888" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/PYNuUQEbwPaeZ2aHA" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a><br></br>
                            <img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/estacionamento-conveniado.png" width="200" /></p>
                            <span class='vpu'><p><a href="tel:+551124091153">(11) 2409-1153</a>&nbsp;-&nbsp;<a href="mailto:guarulhos@oticasgassi.com.br">guarulhos@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Giovanni Gronchi</span><br></br>
                            <span class='vps'>Avenida Giovanni Gronchi, 3363 - Morumbi, São Paulo - SP</span><br></br>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions/br/sp/oticas-gassi?navigate=yes&amp;place=ChIJdyS5D7JPzpQRlQuYK-Bu46g" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/R4MvSu14F3Qjb5vj9" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551137715591">(11) 3771-5591</a>&nbsp;-&nbsp;<a href="mailto:giovannigronchi@oticasgassi.com.br">giovannigronchi@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Interlagos</span><br></br>
                            <span class='vps'>Avenida Interlagos,705 - Jardim Umuarama, São Paulo - SP</span><br></br>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions/br/sp/oticas-gassi?navigate=yes&amp;place=ChIJdyS5D7JPzpQRlQuYK-Bu46g" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/R4MvSu14F3Qjb5vj9" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551123649803">(11) 2364-9883</a>&nbsp;-&nbsp;<a href="mailto:interlagos@oticasgassi.com.br">interlagos@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Lapa</span><br></br>
                            <span class='vps'>Rua Antonio Raposo, 173 - Lapa - São Paulo / SP</span><br></br>
                            <span class='vpu'>Estamos localizados na Travessa da Rua 12 de Outubro, altura do nº 390<br></br>A 300 metros da Estação de Trem/Terminal de Ônibus da Lapa</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.5211522%2C-46.70649648" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/BSVB61FQFYuSzuVJ7" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a><br></br>
                            <img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/estacionamento-conveniado.png" width="200" /></p>
                            <span class='vpu'><p><a href="tel:+551136419522">(11) 3641-9522</a>&nbsp;-&nbsp;<a href="mailto:lapa@oticasgassi.com.br">lapa@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Marginal Tietê</span><br></br>
                            <span class='vps'>Rua Ulisses Cruz, 1343 - Tatuapé - São Paulo/SP (Marginal Tietê sentido Rod Ayrton Senna)</span><br></br>
                            <span class='vpu'>Estamos localizados na Marginal Tietê ao lado do Assaí</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/pt-BR/live-map/directions/brazil/sao-paulo/r.-ulisses-cruz,-1343?navigate=yes&amp;utm_campaign=default&amp;utm_source=waze_website&amp;utm_medium=lm_share_location&amp;to=place.ChIJkzCFjSBfzpQRpP_JHZmhZqc" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/dDo7Nqhh1oy5GNtr5" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551124782627">(11) 2478-2627</a>&nbsp;-&nbsp;<a href="mailto:marginaltiete@oticasgassi.com.br">marginaltiete@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>São Miguel Paulista</span><br></br>
                            <span class='vps'>Avenida Marechal Tito, 1907 - São Miguel Paulista / SP</span><br></br>
                            <span class='vpu'>Estamos próximo a Kalunga</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/pt-BR/live-map/directions/brazil/sao-paulo/avenida-marechal-tito,-1907?navigate=yes&amp;to=place.ChIJPQe-i8JjzpQRu2KjPdMj82U" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/ms4bcGiss3jqZHEL7" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551120314103">(11) 2031-4103</a>&nbsp;-&nbsp;<a href="mailto:novasmp@oticasgassi.com.br">novasmp@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Osasco</span><br></br>
                            <span class='vps'>Avenida dos Autonomistas, 4111 - Vila Yara - Osasco / SP</span><br></br>
                            <span class='vpu'>Estamos localizados ao lado da Renault</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/pt-BR/live-map/directions/brazil/sao-paulo/av.-dos-autonomistas,-4111?navigate=yes&amp;utm_campaign=default&amp;utm_source=waze_website&amp;utm_medium=lm_share_location&amp;to=place.ChIJt_oOOqr_zpQRMQRayDOtGJ4" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/Xawc28XwmJtKUd3H7" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551143849833">(11) 4384-9833</a>&nbsp;-&nbsp;<a href="mailto:novaosasco@oticasgassi.com.br">novaosasco@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS DAS 09H ÀS 16H</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Paraíso</span><br></br>
                            <span class='vps'>Rua Tomás Carvalhal, 152 - Paraíso - São Paulo / SP</span><br></br>
                            <span class='vpu'>Altura do nº 380 da Av. Bernardino de Campos (continuação da Av. Paulista) <br></br>A 200 metros do Metrô Paraíso - SAÍDA C</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.57545331%2C-46.64287448" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/c3fTudFfFVpN8KYN8" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551130512882">(11) 3051-2882</a>&nbsp;-&nbsp;<a href="mailto:paraiso@oticasgassi.com.br">paraiso@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Ponte Freguesia do Ó</span><br></br>
                            <span class='vps'>Rua Doutor Freire Cisneiro, 97 - Freguesia do Ó - São Paulo / SP</span><br></br>
                            <span class='vpu'>Ao lado do Burguer King</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/pt-BR/live-map/directions/brazil/sao-paulo/r.-dr.-freire-cisneiro,-97?navigate=yes&amp;to=place.ChIJQZueBD_4zpQRPLFFu_HeTC8" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/ZEhNr6NW6qHj1RzR9" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551149345696">(11) 4934-5696</a>&nbsp;-&nbsp;<a href="mailto:freguesia@oticasgassi.com.br">freguesia@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS DAS 09H ÀS 16H</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Teotônio Vilela</span><br></br>
                            <span class='vps'>Avenida Senador Teotônio Vilela, 2289 - Cidade Dutra, São Paulo - SP</span><br></br>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" /><a href="https://www.waze.com/en/live-map/directions/br/sp/oticas-gassi?navigate=yes&amp;place=ChIJdyS5D7JPzpQRlQuYK-Bu46g" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a><a href="https://goo.gl/maps/QEt22mFNZm3uQKow9" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551126851817">(11) 2685-1817</a>&nbsp;-&nbsp;<a href="mailto:teotonio@oticasgassi.com.br">teotonio@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS DAS 09H ÀS 16H</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Santana</span><br></br>
                            <span class='vps'>Rua Doutor Zuquim, 518 - Santana - São Paulo / SP</span><br></br>
                            <span class='vpu'>Esquina com a Rua Duarte de Azevedo, a 200 metros do Terminal de Ônibus/Metrô Santana</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.5005117%2C-46.62228584" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/1fHH3meZ2TixrX9u5" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a><br></br>
                            <img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/estacionamento-conveniado.png" width="200" /></p>
                            <span class='vpu'><p><a href="tel:+551129770771">(11) 2977-0771</a>&nbsp;-&nbsp;<a href="mailto:santana@oticasgassi.com.br">santana@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Santo Amaro</span><br></br>
                            <span class='vps'>Alameda Santo Amaro, 183 - Santo Amaro / SP</span><br></br>
                            <span class='vpu'>A 100m da Estação do Metro Lgo 13, ao lado do Armarinhos Fernando</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.65639608%2C-46.70706511" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/nNHRpCWZ17ZUZn7u8" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a><br></br>
                            <img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/estacionamento-conveniado.png" width="200" /></p>
                            <span class='vpu'><p><a href="tel:+551155213395">(11) 5521-3395</a>&nbsp;-&nbsp;<a href="mailto:santoamaro@oticasgassi.com.br">santoamaro@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 19h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Santo André / ABC</span><br></br>
                            <span class='vps'>Rua General Glicério, 335 - Centro - Santo André / SP</span><br></br>
                            <span class='vpu'>A 300 metros da Estação da CPTM - Pref. Celso Daniel / Terminal de Onibus</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.65504974%2C-46.52612329" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/R8MH12a61PbAsquJ7" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a><br></br>
                            <img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/estacionamento-conveniado.png" width="200" /></p>
                            <span class='vpu'><p><a href="tel:+551144276767">(11) 4427-6767</a>&nbsp;-&nbsp;<a href="mailto:santoandre@oticasgassi.com.br">santoandre@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Santos</span><br></br>
                            <span class='vps'>Rua João Pessoa, 230 - Santos / SP</span><br></br>
                            <span class='vpu'>Estamos localizados a 100 metros do Poupa Tempo (na mesma calçada)</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-23.93551558%2C-46.32462502" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/ZwbjYgnjeUb4rzFc8" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a><br></br>
                            <img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/estacionamento-conveniado.png" width="200" /></p>
                            <span class='vpu'><p><a href="tel:+551132224310">(11) 3222-4310</a>&nbsp;-&nbsp;<a href="mailto:santos@oticasgassi.com.br">santos@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 17h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>São Bernardo / ABC</span><br></br>
                            <span class='vps'>Avenida Pereira Barreto, 600 - Baeta Neves - São Bernardo do Campo / SP</span><br></br>
                            <span class='vpu'>Em frente ao Shopping Metrópole Saída Pereira Barreto</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/pt-BR/live-map/directions/brazil/sao-paulo/av.-pereira-barreto,-600?navigate=yes&amp;to=place.ChIJnRDy6zZCzpQRaesk0mAsv3E" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/iy5P2a3m9QbF5WRq5" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551132224310">(11) 4125-4744</a>&nbsp;-&nbsp;<a href="mailto:sbc@oticasgassi.com.br">sbc@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS DAS 09H ÀS 16H</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Taboão</span><br></br>
                            <span class='vps'>Av. Prof. Francisco Morato, 5885 - Jardim Londrina, São Paulo - SP, 05521-300</span><br></br>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/pt-BR/live-map/directions/br/sp/av.-prof.-francisco-morato,-5885?place=ChIJ5cxUhJ9WzpQRKazw2xzpCGA" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/DawfzFu2iq7L3ryaA" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551137713855">(11) 3771-3855</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19:30h - Sábado das 09h às 18h</span><br></br>
                            <span class='vpu'>DOMINGOS DAS 09H ÀS 16H</span>
                        </div>
                    </div>
                    </div>
                </div>
                <br></br>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    <img src={rj} class="xxl" alt="..." /> &nbsp;&nbsp;RIO DE JANEIRO 
                    </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                    <div class='col-md-12 pky'>
                            <span class='xpt'>Centro / RJ</span><br></br>
                            <span class='vps'>Avenida Presidente Vargas, 529 - Centro - Rio de Janeiro</span><br></br>
                            <span class='vpu'>A 100 metros da Estação de Metrô Uruguaiana.</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-22.90175512%2C-43.1803894" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/kQJeqXVHX8GaETEb9" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+552122429590">(21) 2242-9590</a>&nbsp;-&nbsp;<a href="mailto:centrorj@oticasgassi.com.br">centrorj@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 17h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Madureira / RJ</span><br></br>
                            <span class='vps'>Rua Carolina Machado, 530 - Madureira - Rio de Janeiro</span><br></br>
                            <span class='vpu'>A 50 metros da Estação de Trem Madureira, ao lado da Galeria Opção Feira Shopping</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-22.8745837%2C-43.33980918" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/2RZofjt4tJB7Xf9i9" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+55213359313">(21) 3350-9313</a>&nbsp;-&nbsp;<a href="mailto: madureirarj@oticasgassi.com.br">madureirarj@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 17h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Niterói / RJ</span><br></br>
                            <span class='vps'>Rua São João, 67 - Niterói - Rio de Janeiro</span><br></br>
                            <span class='vpu'>A 100 metros do Terminal de Ônibus e Barcas de Niterói, em frente à praça São João.</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?to=ll.-22.89095244%2C-43.12204599" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/zZmqx93Jev8nLhap6" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+551127172497">(21) 2717-2497</a>&nbsp;-&nbsp;<a href="mailto:niteroirj@oticasgassi.com.br">niteroirj@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 17h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADO FECHADO</span>
                        </div>
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Nova Iguaçu / RJ</span><br></br>
                            <span class='vps'>Avenida Nilo Peçanha, 670 - Nova Iguaçu - Rio de Janeiro</span><br></br>
                            <span class='vpu'>Em frente à Kalunga.</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?to=ll.-22.75558429%2C-43.44564915" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/tHEJZPEebkSKj8918" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+552126671554">(21) 2667-1554</a>&nbsp;-&nbsp;<a href="mailto:novaiguacurj@oticasgassi.com.br">novaiguacurj@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 17h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADO FECHADO</span>
                        </div>
                    </div>
                    </div>
                </div>
                <br></br>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    <img src={mg} class="xxl" alt="..." /> &nbsp;&nbsp;   MINAS GERAIS
                    </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Belo Horizonte</span><br></br>
                            <span class='vps'>Rua da Bahia, 932 - Centro - Belo Horizonte - MG</span><br></br>
                            <span class='vpu'>A 100 metros da Praça Sete de Setembro, Travessa da Av. Afonso Pena.</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-19.922727%2C-43.937051" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/fst96FWhFzBDVkxJ7" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+5531664871">(31) 3166-4871</a>&nbsp;-&nbsp;<a href="mailto:belohorizonte@oticasgassi.com.br">belohorizonte@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 09h às 19h - Sábado das 09h às 17h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>
                    </div>
                    </div>
                </div>
                <br></br>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    <img src={ba} class="xxl" alt="..." /> &nbsp;&nbsp;    BAHIA
                    </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <div class='col-md-12 pky'>
                            <span class='xpt'>Salvador</span><br></br>
                            <span class='vps'>Avenida 7 de Setembro, 782 - Centro / Salvador - BA</span><br></br>
                            <span class='vpu'>A 100m do Largo da Piedade e da Estação Lapa.</span>
                            <p><img alt="" height="55" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/rota.png" width="121" />&nbsp;&nbsp;<a href="https://www.waze.com/en/live-map/directions?navigate=yes&amp;to=ll.-12.983447%2C-38.516843" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-waze.png" /></a>&nbsp;<a href="https://goo.gl/maps/miJDbcksSeYMGqjd7" target="_blank"><img alt="" src="https://www.oticasgassi.com.br/assets/image/custom/lojas/icon-maps.png" /></a></p>
                            <span class='vpu'><p><a href="tel:+5531664871">(71) 3329-5299</a>&nbsp;-&nbsp;<a href="mailto:salvador@oticasgassi.com.br">salvador@oticasgassi.com.br</a></p></span>
                            <span class='vpu'>De Segunda a Sexta das 8:30h às 18h - Sábado das 9h às 17h</span><br></br>
                            <span class='vpu'>DOMINGOS E FERIADOS FECHADO</span>
                        </div>                       
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            </div>
    );
}

export default Info3;